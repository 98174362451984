<template>
    <div class="usuario-empresa-salvar">
        <Dialog
            :visible.sync="show"
            :header="`${model.id ? 'Editar' : 'Cadastrar'} usuário`"
            @keyup.esc="show = false"
            modal
        >
            <div v-if="waiting" class="ta-center">
                <ProgressSpinner class="my-4" strokeWidth="2" />
            </div>
            <div v-else>
                <form @submit.prevent="handleSubmit()">
                    <div class="p-grid p-fluid p-align-top">
                        <div :class="['p-col-12 p-md-6', { 'form-group--error': submitted && $v.model.first_name.$error }]">
                            <label class="form-label">Nome:</label>
                            <InputText type="text" v-model="$v.model.first_name.$model" />
                            <div class="feedback--errors" v-if="submitted && $v.model.first_name.$error">
                                <div class="form-message--error" v-if="!$v.model.first_name.minLength">Deve ter pelo menos 2 caracteres.</div>
                                <div class="form-message--error" v-if="!$v.model.first_name.required">Campo obrigatório.</div>
                            </div>
                        </div>
                        <div :class="['p-col-12 p-md-6', { 'form-group--error': submitted && $v.model.last_name.$error }]">
                            <label class="form-label">Sobrenome:</label>
                            <InputText type="text" v-model="$v.model.last_name.$model" />
                            <div class="feedback--errors" v-if="submitted && $v.model.last_name.$error">
                                <div class="form-message--error" v-if="!$v.model.last_name.minLength">Deve ter pelo menos 2 caracteres.</div>
                                <div class="form-message--error" v-if="!$v.model.last_name.required">Campo obrigatório.</div>
                            </div>
                        </div>
                        <div :class="['p-col-12 p-md-6', { 'form-group--error': (submitted && $v.model.username.$error) }]">
                            <label class="form-label">Usuário:</label>
                            <InputText
                                type="text"
                                v-model="$v.model.username.$model"
                                @input="usernameAlreadyExists = false"
                                @blur="checkUsername"
                                :disabled="model.id"
                            />
                            <div class="feedback--errors" v-if="(submitted && $v.model.username.$error)">
                                <div class="form-message--error" v-if="!$v.model.username.required">Campo obrigatório.</div>
                                <div class="form-message--error" v-else-if="!$v.model.username.minLength">Deve ter pelo menos 6 caracteres.</div>
                                <div class="form-message--error" v-else-if="!$v.model.username.alreadyExists">Usuário já cadastrado.</div>
                            </div>
                        </div>
                        <div :class="['p-col-12 p-md-6', { 'form-group--error': submitted && $v.model.email.$error }]">
                            <label class="form-label">E-mail:</label>
                            <InputText type="text" v-model="$v.model.email.$model" />
                            <div class="feedback--errors" v-if="submitted && $v.model.email.$error">
                                <div class="form-message--error" v-if="!$v.model.email.required">Campo obrigatório.</div>
                                <div class="form-message--error" v-else-if="!$v.model.email.email">E-mail inválido.</div>
                            </div>
                        </div>
                        <div :class="['p-col-12 p-md-6', { 'form-group--error': submitted && $v.model.ds_senha.$error }]">
                            <label class="form-label">{{ model.id ? 'Nova senha' : 'Senha' }}:</label>
                            <Password
                                v-model="$v.model.ds_senha.$model"
                                :placeholder="model.id ? '(Deixe em branco se não quiser alterar)' : ''"
                                promptLabel="Crie uma senha"
                                weakLabel="Fraca"
                                mediumLabel="Média"
                                strongLabel="Forte"
                            />
                            <div class="feedback--errors" v-if="submitted && $v.model.ds_senha.$error">
                                <div class="form-message--error" v-if="!$v.model.ds_senha.required">Campo obrigatório.</div>
                                <div class="form-message--error" v-if="!$v.model.ds_senha.minLength">Deve ter pelo menos 6 caracteres.</div>
                            </div>
                        </div>
                        <div :class="['p-col-12 p-md-6', { 'form-group--error': submitted && $v.model.ds_senha_rep.$error }]">
                            <label class="form-label">Confirmação de senha:</label>
                            <InputText type="password" v-model="$v.model.ds_senha_rep.$model" />
                            <div class="feedback--errors" v-if="submitted && $v.model.ds_senha_rep.$error">
                                <div class="form-message--error" v-if="!$v.model.ds_senha_rep.required">Campo é obrigatório.</div>
                                <div class="form-message--error" v-else-if="!$v.model.ds_senha_rep.sameAs">As senhas digitadas são diferentes.</div>
                            </div>
                        </div>
                        <div class="p-col-6 ta-center">
                            <label class="form-label">Habilitado:</label>
                            <InputSwitch v-model="model.is_active" />
                        </div>
                        <div class="p-col-12 ta-right">
                            <Button label="Salvar" type="submit" style='max-width: 160px' />
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    </div>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Password from "primevue/password";
import ProgressSpinner from "primevue/progressspinner";

import { Usuario, Empresa } from "./../../middleware";
import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";

export default {
    components: { Button, Dialog, InputText, InputSwitch, Password, ProgressSpinner },
    props: {
        visible: Boolean,
        cd_empresa: { type: Number, required: true },
        model: {
            type: Object,
            default() {
                return {
                    id: null,
                    first_name: '',
                    last_name: '',
                    username: '',
                    email: '',
                    ds_senha: '',
                    ds_senha_rep: '',
                    is_active: true
                }
            }
        }
    },
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    data () {
        return {
            waiting: false,
            submitted: false,
            usernameAlreadyExists: false,
            currentUsername: ''
        }
    },
    validations () {
        return {
            model: {
                first_name: { required, minLength: minLength(2), maxLength: maxLength(30) },
                last_name: { required, minLength: minLength(2) },
                username: { required, minLength: minLength(6), alreadyExists: () => !this.usernameAlreadyExists },
                email: { required, email },
                ds_senha: {
                    required: this.model.id ? (this.model.ds_senha ? required : true) : required,
                    minLength: minLength(6)
                },
                ds_senha_rep: {
                    required: this.model.id ? (this.model.ds_senha ? required : true) : required,
                    sameAs: sameAs('ds_senha')
                }
            }
        }
    },
    methods: {
        checkUsername () {
            if (this.model.username.length >= 6 && this.model.username !== this.currentUsername) {
                Usuario.find(this.model.username).then(response => {
                    if (response.status === 200) this.usernameAlreadyExists = true;
                })
            }
        },
        handleSubmit () {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return;

            let dataSend = Object.assign({}, this.model);
            dataSend.cd_empresa = this.cd_empresa;

            if (dataSend.id && !dataSend.ds_senha) {
                delete dataSend.ds_senha;
                delete dataSend.ds_senha_rep;
            }

            this.waiting = true;
            Empresa.saveUsuario(dataSend).then(response => {
                this.waiting = false;
                if ([200, 201].includes(response.status)) {
                    this.$toast.success('Usuário salvo com sucesso.', { duration: 3000 });
                    this.currentUsername = dataSend.username;
                    this.show = false;
                    this.$emit('saved');
                } else if ([400, 404].includes(response.status)){
                    let msg = response.data.detail;
                    if (!msg) {
                        Object.keys(response.data).forEach(key => {
                            msg = response.data[key];
                            if (typeof response.data[key] == 'object') msg = `${key}: ${response.data[key][0]}`;
                        });
                    }
                    this.$toast.error(msg, { duration: 3000 });
                }
            });
        }
    }
}
</script>

<style lang="scss">
.usuario-empresa-salvar {
    .p-dialog {
        width:98%;
        max-width: 700px;
        .p-dialog-content {
            overflow:visible !important;
        }
    }
    .p-inputtext[disabled=disabled] {
        color: #000;
        font-weight: 600;
        background-color: #ddd;
    }
    .p-picklist-source-controls,
    .p-picklist-target-controls {
        display: none;
    }
    .p-picklist-source-wrapper,
    .p-picklist-target-wrapper {
        width: 45%;
        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
    .p-picklist-buttons-cell {
        text-align: center;
        .p-button {
            display: none !important;
            &:first-of-type, &:nth-of-type(3) {
                display: inline-block !important;
            }
        }
    }
}
</style>
