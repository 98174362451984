<template lang="pug">
div
    form(ref="formEndereco" @change='emitir()')
        .p-grid.p-fluid.p-align-end
            .p-col-12.p-md-1
                label.form-label CEP *:
                InputText(type='text' v-model='$v.model.nr_cep.$model' @change="getEnderecoByCep()")
                .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                    .form-message--error(v-if="!$v.model.nr_cep.required") <b>CEP</b> obrigatório.

            .p-col-12.p-md-2
                label.form-label Bairro *:
                InputText(type='text' v-model='$v.model.nm_bairro.$model')
                .feedback--errors(v-if='submitted && $v.model.nm_bairro.$error')
                    .form-message--error(v-if="!$v.model.nm_bairro.minLength") <b>Bairro</b> deve ter pelo menos 2 caracteres.
                    .form-message--error(v-if="!$v.model.nm_bairro.required") <b>Bairro</b> é obrigatório.

            .p-col-12.p-md-2
                label.form-label Rua *:
                InputText(type='text' v-model='$v.model.nm_logradouro.$model')
                .feedback--errors(v-if='submitted && $v.model.nm_logradouro.$error')
                    .form-message--error(v-if="!$v.model.nm_logradouro.minLength") <b>Rua</b> deve ter pelo menos 2 caracteres.
                    .form-message--error(v-if="!$v.model.nm_logradouro.required") <b>Rua</b> é obrigatório.

            .p-col-12.p-md-1
                label.form-label Número *:
                InputText(type='text' v-model='$v.model.nr_numero.$model' @blur="emitir()")
                .feedback--errors(v-if='submitted && $v.model.nr_numero.$error')
                    .form-message--error(v-if="!$v.model.nr_numero.minLength") <b>Número</b> deve ter pelo menos 2 caracteres.
                    .form-message--error(v-if="!$v.model.nr_numero.required") <b>Número</b> é obrigatório.

            .p-col-12.p-md-2(@blur="emitir()")
                label.form-label Complemento:
                InputText(type='text' v-model='model.ds_complemento')


            .p-col-12.p-md-2
                ProgressBar(v-if="waitingEstado" mode="indeterminate")
                div(v-else)
                    label.form-label Estado *:
                    Dropdown(v-model='model.cd_estado' :options='options.ufs' @change='getCidades()'
                        optionLabel='textAlt' placeholder='Selecione' filter)

            .p-col-12.p-md-2
                ProgressBar(v-if="waitingCidade" mode="indeterminate")
                div(v-else)
                    label.form-label Município *:
                    Dropdown(v-model='model.cidade' :options='options.cidades' :disabled="!model.cd_estado"
                        @change="emitir()"
                        optionLabel='text' placeholder='Selecione' filter)


</template>

<style></style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import { required, minLength } from 'vuelidate/lib/validators'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'

    import { PessoaJuridica, Utils } from '../../../middleware'


    export default {
        components: {
            ProgressBar, InputText, InputMask, Button, Dropdown
        },
        created () {
            this.getEstados()
        },
        props: ['parentData', 'pessoaJuridicaId'],
        data () {
            return {
                waitingEstado: false,
                waitingCidade: false,
                submitted: false,
                model: {
                    id: this?.parentData?.id || null,
                    nm_logradouro: this?.parentData?.nm_logradouro || null,
                    nm_bairro: this?.parentData?.nm_bairro || null,
                    nr_numero: this?.parentData?.nr_numero || null,
                    nr_cep: this?.parentData?.nr_cep || null,
                    ds_complemento: this?.parentData?.ds_complemento || null,
                    cd_cidade: this?.parentData?.cd_cidade || null,
                    cd_estado: this?.parentData?.cd_estado || null,
                    cd_pessoa_juridica: this?.pessoaJuridicaId || null,
                    cidade: null
                },
                options: {
                    cidades: [],
                    ufs: []
                }
            }
        },
        validations() {
            let v = {
                model: {
                    nm_logradouro: { required, minLength: minLength(2) },
                    nm_bairro: { required, minLength: minLength(2) },
                    nr_numero: { required, minLength: minLength(2) },
                    nr_cep: { required, minLength: minLength(2) },
                }
            }

            return v
        },
        methods: {
            getEnderecoByCep() {
                 const cep = this.model.nr_cep.replace(/\D/g, "")
                 Utils.getCepViaCep(cep).then(response => {
                     if(response.status == 200) {
                         this.model.nm_bairro = response.data?.bairro
                         this.model.nm_logradouro = response.data?.logradouro
                         this.model.cd_estado = this.options.ufs.find(uf => uf.text === response.data?.uf)
                         const viacep_cidade = response.data?.localidade
                         if (this.model.cd_estado) {
                             this.waitingCidade = true
                             Utils.getMunicipiosEstadoId(this.model.cd_estado.value).then(response => {
                                 this.waitingCidade = false
                                 this.options.cidades = [{ value: null, text: '- Selecione -' }]
                                 if (response.status === 200) response.data.forEach(cidade => {
                                     this.options.cidades.push({ value: cidade.id, text: cidade.nm_cidade })
                                     if (cidade.nm_cidade === viacep_cidade) {
                                         this.model.cidade = { value: cidade.id, text: cidade.nm_cidade }
                                     }
                                 })
                             })
                         }
                     }
                 })
            },
            getEstados() {
                this.waitingEstado = true
                Utils.getUFs().then(response => {
                    this.waitingEstado = false
                    this.options.ufs = [{ value: null, text: '- Selecione -', textAlt: '- Selecione -' }]
                    if (response.status === 200) response.data.forEach(uf => {
                        this.options.ufs.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado })
                        if (uf.id === this.model.cd_estado) {
                            this.model.cd_estado = { value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado }
                            this.getCidades()
                        }
                    })
                })
            },
            getCidades() {
                this.options.cidades = []
                if (!this.model.cd_estado) return

                this.waitingCidade = true
                Utils.getMunicipiosEstadoId(this.model.cd_estado.value).then(response => {
                    this.waitingCidade = false
                    this.options.cidades = [{ value: null, text: '- Selecione -' }]
                    if (response.status === 200) response.data.forEach(cidade => {
                        this.options.cidades.push({ value: cidade.id, text: cidade.nm_cidade })
                        if (cidade.id == this.model.cd_cidade) {
                            this.model.cidade = { value: cidade.id, text: cidade.nm_cidade }
                        }
                    })
                })
            },
            isValid() {
                const fields = this.model.nr_cep && this.model.nm_bairro && this.model.nr_numero && this.model.nm_logradouro
                const fk = this.model?.cidade?.value
                return fields && fk
            },
            emitir() {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) {
                    console.log('invalid')
                    return 0
                }
                else this.$v.$reset()
                if (this.isValid()) {
                    this.model.nr_cep = this.model.nr_cep.replace(/\D/g, "")
                    this.model.cd_cidade = this.model.cidade.value
                    if (this.model.id) {
                        PessoaJuridica.saveEndereco(this.model).then(response => {
                            if ([200,201].includes(response.status)) {
                                this.$toast.success('Endereço salvo com sucesso', { duration: 3000 })
                            } else {
                                if (typeof response.data.detail == 'string')
                                    this.$toast.error(response.data.detail, { duration: 3000 })
                                else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                            }
                        })
                    }
                    this.$emit('enderecoEmitido', [this.model])
                    this.$v.$reset()
                }
            },
        }
    }

</script>
