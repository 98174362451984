<template lang="pug">
    .main-wrapper.empresa-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "empresas" }'> Empresas</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            DialogUsuario(
                v-if="usuario.dialog"
                :visible="usuario.dialog"
                :model="usuario.model"
                :cd_empresa="model.id"
                @close="usuario.dialog = false"
                @saved="getUsuariosEmpresa()")

            Dialog.dialogFaixa(header='Valores por faixa etária' :modal='true' :visible.sync='dialogFaixa')
                .p-grid.p-fluid.p-align-end
                    .p-col-6(v-for='i in faixasEtarias' :key='i.value')
                        label.form-label {{ i.label }}:
                        CurrencyInput(
                            v-model='i.nr_valor'
                            locale='pt-BR'
                            :auto-decimal-mode='true'
                            :value-range='{ min: 0 }'
                        )
                    .p-col-12.ta-center
                        //- ProgressSpinner(v-if='waitingInativar' strokeWidth='6')
                        Button.p-button-warning( icon='jam jam-plus-circle' label='Confirmar' @click='dialogFaixa = false')


            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm || waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } empresas`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.ta-right(v-if="model.cd_pessoa_juridica.id")
                                a.link(@click="$router.push(`/pessoa-juridica/salvar/${model.cd_pessoa_juridica.id}/`)") Ir para Pessoa Juridica
                            .p-col-12.p-md-2
                                ProgressBar(v-if="waitingTipo" mode="indeterminate")
                                div(v-else)
                                    label.form-label Tipo Pessoa Juridica:
                                    Dropdown(
                                        v-model='model.cd_pessoa_juridica.ie_tipo_pessoa_juridica'
                                        :options='options.tipos'
                                        optionLabel='ds_valor'
                                        optionValue='ie_valor'
                                        placeholder='Selecione'
                                    )
                            .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.nr_cnpj.$error }")
                                label.form-label CNPJ:
                                InputMask(mask='99.999.999/9999-99' v-model='$v.model.cd_pessoa_juridica.nr_cnpj.$model')
                                .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.nr_cnpj.$error')
                                    .form-message--error(v-if="!$v.model.cd_pessoa_juridica.nr_cnpj.minLength") <b>CNPJ</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.cd_pessoa_juridica.nr_cnpj.required") <b>CNPJ</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.ds_razao_social.$error }")
                                label.form-label Razão Social:
                                InputText(type='text' v-model='$v.model.cd_pessoa_juridica.ds_razao_social.$model')
                                .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.ds_razao_social.$error')
                                    .form-message--error(v-if="!$v.model.cd_pessoa_juridica.ds_razao_social.minLength") <b>Razão Social</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.cd_pessoa_juridica.ds_razao_social.required") <b>Razão Social</b> é obrigatório.


                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.nm_fantasia.$error }")
                                label.form-label Nome fantasia:
                                InputText(type='text' v-model='$v.model.cd_pessoa_juridica.nm_fantasia.$model')
                                .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.nm_fantasia.$error')
                                    .form-message--error(v-if="!$v.model.cd_pessoa_juridica.nm_fantasia.minLength") <b>Nome Fantasia</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.cd_pessoa_juridica.nm_fantasia.required") <b>Nome Fantasia</b> é obrigatório.

                            .p-col-12.p-md-2
                                ProgressBar(v-if="waitingTipoEmpresa" mode="indeterminate")
                                div(v-else)
                                    label.form-label Tipo de Empresa:
                                    Dropdown(
                                        v-model='model.cd_tipo_empresa'
                                        :options='options.tiposEmpresa'
                                        optionLabel='text'
                                        optionValue='value'
                                        placeholder='Selecione'
                                    )

                            .p-col-12.p-md-1
                                label.form-label IE:
                                InputText(type='text' v-model='model.cd_pessoa_juridica.nr_inscricao_estadual')

                            .p-col-12.p-md-1
                                label.form-label IM:
                                InputText(type='text' v-model='model.cd_pessoa_juridica.nr_inscricao_municipal')

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.planos_contas.$error }")
                                ProgressBar(v-if="waitingPlanoContas" mode="indeterminate")
                                div(v-else)
                                    label.form-label Conta Financeira:
                                    MultiSelect(
                                        :options='options.contasFinanceiras'
                                        placeholder='Selecione'
                                        optionLabel='ds_descricao'
                                        dataKey='id'
                                        :filter='true'
                                        v-model='model.cd_pessoa_juridica.planos_contas'
                                    )
                                .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.planos_contas.$error')
                                    .form-message--error(v-if="!$v.model.cd_pessoa_juridica.planos_contas.required") <b>Plano Contas</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_pessoa_juridica.cnaes.$error }")
                                ProgressBar(v-if="waitingCnae" mode="indeterminate")
                                div(v-else)
                                    label.form-label Código CNAE:
                                    MultiSelect(
                                        :options='options.cnaes'
                                        placeholder='Selecione'
                                        optionLabel='ds_descricao'
                                        dataKey='cod_cnae'
                                        :filter='true'
                                        v-model='model.cd_pessoa_juridica.cnaes'
                                    )

                                .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.cnaes.$error')
                                    .form-message--error(v-if="!$v.model.cd_pessoa_juridica.cnaes.required") <b>CNAE</b> é obrigatório.

                            .p-col-12.p-md-2
                                label.form-label Emitir NF Automaticamente:
                                Checkbox(v-model='model.cd_pessoa_juridica.ie_nf_automatica' :binary="true")

                            //- .p-col-12.p-md-3
                            //-     label.form-label ISS retido:
                            //-     InputSwitch( v-model='model.ie_iss_retido')
                            //- .p-col-12.mt-2.mb-2(v-if="model.id")
                    br
                    div(v-if="!model.id")
                        Panel(header="Endereço")
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.enderecos.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.enderecos.required") <b>Endereço</b> é obrigatório.
                            br
                            Endereco(:parentData="model.cd_pessoa_juridica.enderecos[0]" :pessoaJuridicaId="model.id" @enderecoEmitido="handleEndereco" ref="enderecoComponent")
                        br
                        Panel(header="Contatos")
                            Contato(:parentData="model.cd_pessoa_juridica.contatos" :pessoaJuridicaId="model.id" @contatosEmitido="handleContatos" ref="contatoComponent")
                        br
                        Panel(header="Dados Bancários")
                            .feedback--errors(v-if='submitted && $v.model.cd_pessoa_juridica.bancos.$error')
                                .form-message--error(v-if="!$v.model.cd_pessoa_juridica.bancos.required") <b>Ao menos um banco</b> é obrigatório.
                                br
                            DadosBancarios(:parentData="model.cd_pessoa_juridica.bancos" :pessoaJuridicaId="model.id" @bancosEmitido="handleBancos" ref="dadosBancariosComponent")
                        br
                        Panel(header="Impostos")
                            Impostos(:parentData="model.cd_pessoa_juridica.tributos" @tributosEmitidos="handleTributos" ref="tributosComponent")
                        br
                    .p-grid(v-else)
                        .p-col-12.p-md-6.p-lg-3
                            span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                        .p-col-12.p-md-6.p-lg-3
                            span <b>Data da criação: </b> {{ model.dt_criado_f }}
                        .p-col-12.p-md-6.p-lg-3
                            span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                        .p-col-12.p-md-6.p-lg-3
                            span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}
                .ta-right
                    Button(label='Salvar' icon='jam jam-check' type="submit" style='max-width:160px')

            Panel.datatable.mt-3(header="Usuários" v-if="model.id")

                ProgressBar(v-if='usuario.waiting || waiting' mode="indeterminate")

                div(v-else-if="!usuarios.length")
                    p.ta-center Nenhum usuário cadastrado!

                .ta-center(v-else)
                    DataTable(:value="usuarios")
                        Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
                            template(#body='props')
                                ProgressSpinner.waiting-status(v-if='usuario.statusIds.includes(props.data.id)' strokeWidth='6')
                                .status-indicator(
                                    v-else :class="{ off: ! props.data.is_active }"
                                    @click='alterarStatusUsuario(props.data)')
                                    i.jam(:class="{ 'jam-check': props.data.is_active, 'jam-minus': !props.data.is_active }")
                        Column(headerStyle='width: 35%;' bodyStyle='text-align: left;' field='full_name' header='Nome')
                        Column(headerStyle='width: 25%;' field='username' header='Usuário')
                        Column(headerStyle='width: 25%;' field='email' header='Email')
                        Column(headerStyle='width: 10%;' header="Ações")
                            template(#body='props')
                                .ta-center
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Editar'"
                                        type="button"
                                        icon="jam jam-write"
                                        @click="onpenDialogUsuario(props.data)"
                                    )

                .p-grid.p-fluid.p-align-end.mt-2
                    .p-col-12.ta-right
                        Button(
                            label='Adicionar'
                            icon='jam jam-plus'
                            style='max-width: 160px'
                            :disabled='usuario.waiting'
                            @click="onpenDialogUsuario()")

            Panel.datatable.mt-3(header="Dados contratuais" v-if='model.id && model.cd_tipo_empresa == 2')
                .p-inputgroup.ta-left(v-if='modelContrato.aq_anexo && !arquivoContrato')
                    Button.p-button-success(
                        style="margin-right: flex-end;max-width:20%;"
                        icon="jam jam-download"
                        label="Baixar Contrato"
                        type='button'
                        @click="downaloadContrato()")
                    Button.p-button-warning( type='button' icon='jam jam-attachment' @click='$refs.arquivoContrato.click()' v-tooltip.top="'Anexar novo contrato'")
                .ta-left(v-else)
                    Button.p-button-success(
                        v-if='!arquivoContrato'
                        style="margin-right: flex-end;max-width:20%;"
                        icon="jam jam-attachment"
                        label="Inserir arquivo de contrato"
                        type='button'
                        @click="$refs.arquivoContrato.click()")

                    .p-inputgroup(v-else)
                        InputText(type="text" placeholder="Anexar comprovante" v-model="arquivoContrato[0].name"
                            @click="$refs.arquivoContrato.click()" readonly)
                        Button.p-button-success(icon='jam jam-attachment' @click="$refs.arquivoContrato.click()" v-tooltip.top="'Anexar'")
                input(
                    v-show='false'
                    type="file"
                    ref="arquivoContrato"
                    @change="anexarContrato()"
                    accept=".pdf")

                .p-grid.p-fluid.p-align-start
                    .p-col-12.p-md-12
                        label.form-label Descrição do contrato:
                        Textarea(type='text' v-model='modelContrato.ds_contrato'
                            style='width: 100%; padding: 5px'
                            :autoResize="true"
                            rows="4"
                        )


                    .p-col-12.p-md-4
                        label.form-label Nome do responsável:
                        InputText(type='text' v-model='modelContrato.nm_responsavel')


                    .p-col-12.p-md-4
                        label.form-label CPF do responsável:
                        InputMask.text-readonly( type='tel' v-model='modelContrato.nr_cpf' mask='999.999.999-99' :placeholder="modelContrato.responsavel ? '(Opcional)' : ''")


                    .p-col-12.p-md-4
                        label.form-label Telefone:
                        InputMask( type='tel' v-model='modelContrato.nr_telefone' mask='(99) 99999999?9')

                    .p-col-12.p-md-4(:class="{ 'form-group--error': submittedContrato && $v.modelContrato.dt_vigencia_inicial.$error }")
                        label.form-label Data Inicial de Vigência:
                        .p-inputgroup
                            Calendar( v-model='modelContrato.dt_vigencia_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                                :manualInput='false' )
                            Button(label='Hoje' type='button' icon='jam jam-calendar' @click='setMinDate()')
                            Button.p-button-danger(icon='jam jam-rubber' @click="() => {modelContrato.dt_vigencia_inicial = null; modelContrato.dt_vigencia_final = null}")
                        .feedback--errors(v-if='!intervalo_vigencia_valido')
                            .form-message--error Intervalo de vigência inválido.

                    .p-col-12.p-md-4
                        label.form-label Data Final de Vigência:
                        .p-inputgroup
                            Calendar( v-model='modelContrato.dt_vigencia_final' dateFormat="dd/mm/yy" :locale="ptbr"
                                :manualInput='false' :minDate='modelContrato.dt_vigencia_inicial')
                            Button.p-button-danger(icon='jam jam-rubber' @click="() => {modelContrato.dt_vigencia_final = null}")
                        //- .feedback--errors(v-if='!intervalo_vigencia_valido')
                        //-     .form-message--error Intervalo de vigência inválido.

                    .p-col-12.p-md-4(:class="{ 'form-group--error': submittedContrato && $v.modelContrato.ie_tipo_contrato.$error }")
                        label.form-label Tipo de contrato:
                        Dropdown(v-model='modelContrato.ie_tipo_contrato' :options='options.ie_tipo_contrato'
                            optionLabel='label' optionValue='value' placeholder='Selecione')
                        .feedback--errors(v-if='submittedContrato && $v.modelContrato.ie_tipo_contrato.$error')
                            .form-message--error(v-if="!$v.modelContrato.ie_tipo_contrato.required") <b>Tipo de contrato</b> é obrigatório.

                    .p-col-12.p-md-4
                        label.form-label Nº de vidas contempladas:
                        InputNumber(v-model='modelContrato.nr_vidas_contempladas' mode='decimal')
                        //- .feedback--errors(v-if='submittedContrato && $v.modelContrato.nr_vidas_contempladas.$error')
                        //-     .form-message--error(v-if="!$v.modelContrato.nr_vidas_contempladas.required") <b>Nº de vidas contempladas</b> é obrigatório.

                    .p-col-12.p-md-4
                        label.form-label Tipo de valor de intermediação:
                        Dropdown(v-model='modelContrato.ie_tipo_valor' :options='options.ie_tipo_valor'
                            optionLabel='label' optionValue='value' placeholder='Selecione')

                    .p-col-12.p-md-4(v-if="modelContrato.ie_tipo_valor == 'VL'" )
                        label.form-label Valor Intermediação:
                        CurrencyInput(
                            v-model='modelContrato.nr_valor_intermediacao'
                            locale='pt-BR'
                            :auto-decimal-mode='true'
                            :value-range='{ min: 0 }'
                        )
                        //- .feedback--errors(v-if='submittedContrato && $v.modelContrato.nr_valor_intermediacao.$error')
                        //-     .form-message--error(v-if="!$v.modelContrato.nr_valor_intermediacao.required") <b>Valor de intermediação</b> é obrigatório.

                    .p-col-12.p-md-4(v-else)
                        label.form-label Valor Intermediação:
                        InputNumber(
                            suffix="%"
                            v-model='modelContrato.nr_percentual_intermediacao'
                            :min='0'
                            :max='100'
                        )
                        //- .feedback--errors(v-if='submittedContrato && $v.modelContrato.nr_percentual_intermediacao.$error')
                        //-     .form-message--error(v-if="!$v.modelContrato.nr_percentual_intermediacao.required") <b>Valor de intermediação</b> é obrigatório.
                    .p-col-12.p-md-4
                        label.form-label Tipo de Mensalidade:
                        Dropdown(v-model='tipo_mensalidade' :options='options.tipo_mensalidade'
                            optionLabel='text' optionValue='value' placeholder='Selecione')

                    .p-col-12.p-md-4(v-if='tipo_mensalidade == 0')
                        label.form-label Valor Mensalidade:
                        CurrencyInput(
                            v-model='modelContrato.vl_mensalidade'
                            locale='pt-BR'
                            :auto-decimal-mode='true'
                            :value-range='{ min: 0 }'
                        )
                    .p-col-12.p-md-4(v-else)
                        label <br>
                        div.ta-center
                            Button.p-button(
                                type='button'
                                icon="jam jam-plus"
                                label="Adicionar Valores por faixa"
                                @click="dialogFaixa = true")

                    .p-col-12.p-md-4( :class="{ 'form-group--error': submittedContrato && $v.modelContrato.nr_dia_vencimento_mensalidade.$error }")
                        label.form-label Dt. vencimento da mensalidade:
                        InputNumber(
                            v-model='modelContrato.nr_dia_vencimento_mensalidade'
                            :min='0'
                            :max='31'
                        )
                        .feedback--errors(v-if='submittedContrato && $v.modelContrato.nr_dia_vencimento_mensalidade.$error')
                            .form-message--error(v-if="!$v.modelContrato.nr_dia_vencimento_mensalidade.required") <b></b> é obrigatório.

                    .p-col-12.p-md-4( :class="{ 'form-group--error': submittedContrato && $v.modelContrato.nr_dia_vencimento_resgate.$error }")
                        label.form-label Dt. vencimento da resgate:
                        InputNumber(
                            v-model='modelContrato.nr_dia_vencimento_resgate'
                            :min='0'
                            :max='31'
                        )
                        .feedback--errors(v-if='submittedContrato && $v.modelContrato.nr_dia_vencimento_resgate.$error')
                            .form-message--error(v-if="!$v.modelContrato.nr_dia_vencimento_resgate.required") <b></b> é obrigatório.

                    .p-col-12.p-md-4
                        label.form-label Dt. vencimento de taxa:
                        InputNumber(
                            v-model='modelContrato.nr_dia_vencimento_taxa'
                            :min='0'
                            :max='31'
                        )
                        //- .feedback--errors(v-if='submittedContrato && $v.modelContrato.nr_dia_vencimento_taxa.$error')
                        //-     .form-message--error(v-if="!$v.modelContrato.nr_dia_vencimento_taxa.required") <b></b> é obrigatório.

                    .p-col-12.p-md-4
                        label.form-label Contempla dependentes:
                        InputSwitch( v-model='modelContrato.ie_contempla_dependentes')

                    .p-col-12.p-md-12
                        label.form-label Observações:
                        Textarea(type='text' v-model='modelContrato.ds_observacao'
                            style='width: 100%; padding: 5px'
                            :autoResize="true"
                            rows="4"
                        )
                        //- .feedback--errors(v-if='submittedContrato && $v.modelContrato.ds_observacao.$error')
                        //-     .form-message--error(v-if="!$v.modelContrato.ds_observacao.minLength") <b>Descrição de contrato</b> deve ter pelo menos 15 caracteres.
                        //-     .form-message--error(v-if="!$v.modelContrato.ds_observacao.required") <b>Descrição de contrato</b> é obrigatório.

                    .p-col-12.ta-right
                        ProgressSpinner(v-if='waitingContrato' strokeWidth='6')
                        Button(v-else label='Salvar' @click='submitContrato()' icon='jam jam-check' type="button" style='max-width:160px')

            Panel.datatable.mt-3(
                :header="`Lista de ${model.cd_tipo_empresa === 1 ? 'pacientes' : 'associados'}`"
                v-if="model.id")

                .p-grid.p-fluid.p-align-end
                    .p-col-4
                        label.form-label Nome:
                        .p-inputgroup
                            InputText(type='text'  v-model='filters.nm_pessoa_fisica' @keyup.enter="filtrarAssociados()")
                            Button.p-button-danger(
                                v-if='filters.nm_pessoa_fisica.length'
                                icon='jam jam-rubber'
                                @click="filters.nm_pessoa_fisica = ''"
                            )

                    .p-col-3
                        label.form-label CPF:
                        .p-inputgroup
                            InputMask(
                                :mask="'999.999.999-99'"
                                v-model="filters.nr_cpf"
                                @keydown.enter="filtrarAssociados()"
                                :autoClear="false")
                            Button.p-button-danger(
                                v-if='filters.nr_cpf.length'
                                icon='jam jam-rubber'
                                @click="filters.nr_cpf = ''"
                            )

                    .p-col-3
                        label.form-label Motivo de inativação:
                        Dropdown(v-model='filters.ie_motivo' :options='options.ie_motivo'
                            optionLabel='label' optionValue='value' @change="filtrarAssociados()" placeholder='Selecione')

                    .p-col-2
                        label.form-label Status:
                        Dropdown(v-model='filters.ie_status' :options='options.ie_status'
                            optionLabel='text' optionValue='value' @change="filtrarAssociados()" placeholder='Selecione')


                    //- .p-col-2
                    //-     Button(
                                //-         label='Filtrar'
                                //-         icon='jam jam-search'
                                //-         :disabled='waitingAssociados'
                                //-         @click="filtrarAssociados()")

                    .p-col-5
                        label.form-label Inclusão de Correntista:
                        .p-inputgroup
                            Dropdown(
                                v-model='tipo_data_inclusao'
                                :options='options.tipo_data'
                                optionLabel='label'
                                optionValue='value_inc'
                                dataKey='value_inc'
                                :style="{'width':'30%'}"
                            )
                            Calendar(
                                v-model="filters[tipo_data_inclusao]"
                                :dateFormat="tipo_data_inclusao == 'dt_competencia_inclusao' ? 'mm/yy' : 'dd/mm/yy'"
                                :placeholder="tipo_data_inclusao == 'dt_competencia_inclusao' ? 'MM/AAAA' : 'DD/MM/AAAA'"
                                :view="tipo_data_inclusao == 'dt_competencia_inclusao' ? 'month' : 'date'"
                                :selectionMode="tipo_data_inclusao == 'dt_competencia_inclusao' ? 'single' : 'range'"
                                :locale="pt_br"
                                :monthNavigator="true"
                                :yearNavigator="true"
                                :manualInput="true"
                                :yearRange="`2020:${esseAno}`"
                                :style="{'width':'70%'}"
                                @keydown.enter="filtrarAssociados()"
                            )
                            Button.p-button-danger(
                                v-if="filters[tipo_data_inclusao]"
                                icon='jam jam-rubber'
                                @click="filters[tipo_data_inclusao] = null"
                            )
                    .p-col-5
                        label.form-label Período de Atividade:
                        .p-inputgroup
                            Dropdown(
                                v-model='tipo_data_atividade'
                                :options='options.tipo_data'
                                optionLabel='label'
                                optionValue='value_atv'
                                dataKey='value_atv'
                                :style="{'width':'30%'}"
                            )
                            Calendar(
                                v-model="filters[tipo_data_atividade]"
                                :dateFormat="tipo_data_atividade == 'dt_competencia' ? 'mm/yy' : 'dd/mm/yy'"
                                :placeholder="tipo_data_atividade == 'dt_competencia' ? 'MM/AAAA' : 'DD/MM/AAAA'"
                                :view="tipo_data_atividade == 'dt_competencia' ? 'month' : 'date'"
                                :selectionMode="tipo_data_atividade == 'dt_competencia' ? 'single' : 'range'"
                                :locale="pt_br"
                                :monthNavigator="true"
                                :yearNavigator="true"
                                :manualInput="true"
                                :yearRange="`2020:${esseAno}`"
                                :style="{'width':'70%'}"
                                @keydown.enter="filtrarAssociados()"
                            )
                            Button.p-button-danger(
                                v-if='filters[tipo_data_atividade]'
                                icon='jam jam-rubber'
                                @click="filters[tipo_data_atividade] = null"
                            )
                    .p-col-2
                        label <br>
                        Button(
                            label='Filtrar'
                            icon='jam jam-search'
                            :disabled='waitingAssociados'
                            @click="filtrarAssociados()"
                            :style="{'cursor':waitingAssociados ? 'wait' : 'pointer'}")
                    .p-col-12.ta-right
                        Button(
                            style="margin-right: flex-end;max-width:20%;margin-left: 10px"
                            label='Limpar Filtros'
                            icon='jam jam-rubber'
                            @click="limparFiltros()")

                .p-grid.p-fluid.p-align-start
                    .p-col-12.ta-right(v-if='!isAdd')
                        Button.p-button-success(
                            style="margin-right: flex-end;max-width:20%;"
                            icon="jam jam-attachment"
                            label="Inserir arquivo de correntistas"
                            @click="$refs.arquivoCorrentistas.click()")
                        Button(
                            label='Adicionar correntista'
                            icon='jam jam-plus'
                            style='max-width:15.8%; margin-left: 10px'
                            @click="isAdd = true")


                    .p-col-5.buscar-paciente(:class="{ naoVer: !isAdd}")
                        BuscarPaciente(ref='buscarPaciente2' label='Cliente' :cancelarPopup='true' @change='clienteAlterado = true' @limpa='clienteAlterado = false; isAdd = false;'  @busca='clienteAlterado = true')

                    .p-col-2(v-if="isAdd")
                        label.form-label Aporte:
                        CurrencyInput(
                            v-model='valorAporte'
                            locale='pt-BR'
                            :auto-decimal-mode='true'
                            :value-range='{ min: 0 }'
                                    )
                    .p-col-3(v-if="isAdd")
                        label <br>
                        div.ta-center
                            Button.p-button(
                                v-if='!arquivoContrato'
                                icon="jam jam-attachment"
                                label="Inserir Termo"
                                @click="$refs.arquivoContrato.click()")

                            .p-inputgroup(v-else)
                                InputText(type="text" placeholder="Anexar comprovante" v-model="arquivoContrato[0].name"
                                    @click="$refs.arquivoContrato.click()" readonly)
                                Button.p-button(icon='jam jam-attachment' @click="$refs.arquivoContrato.click()" v-tooltip.top="'Anexar'")

                    .p-col-2(v-if="isAdd && !waitingAssociados")
                        label <br>
                        div.ta-center
                            ProgressSpinner(v-if='waitingAddC' strokeWidth='4')
                            Button(
                                v-else
                                label='Vincular Correntista'
                                icon='jam jam-plus'
                                :disabled='!clienteAlterado'
                                @click="addCorrentista()")

                    .p-col-12(v-if="isAdd")
                        .ta-right
                            Button(
                                label='Cancelar'
                                style='width:16%'
                                icon='jam jam-close'
                                @click="isAdd = false; clienteAlterado = false;")
                    
                  
                input(
                    v-show='false'
                    type="file"
                    ref="arquivoCorrentistas"
                    @change="anexarCorrentistas()"
                    accept=".xlsx")

                ProgressBar(v-if='waitingAssociados || waiting' mode="indeterminate")

                div(v-else-if="!listaAssociados.length")
                    p.ta-center Nenhum item encontrado

                div(v-else)
                    Paginator.mb-1(
                        :rows='paginatorAssociados.per_page'
                        :first='(paginatorAssociados.page - 1) * paginatorAssociados.per_page'
                        :totalRecords='paginatorAssociados.count'
                        @page="onPageAssociados")
                    DataTable(:value="listaAssociados")
                        Column(headerStyle="width: 16%;" field="nm_pessoa_fisica" header="Correntista")
                            template(#body='{data}')
                                b {{ data.nm_pessoa_fisica }}
                                br
                                em
                                    b CPF:
                                    span {{ data.nr_cpf_f }}
                                br
                                em
                                    b Tel.:
                                    span {{ data.nr_telefone_f }}
                                br
                                em
                                    b Sexo:
                                    span {{ data.ie_sexo_f }}
                                .ta-right.my-2
                                    b.tag-restrito.ta-right(v-tooltip.top="'Usuário Inativo'" v-if="!data.ie_status") Inativo
                                .ta-right.my-2
                                    b.tag-ae.ta-right(v-tooltip.top="'Uma solicitação de exlusão foi gerada para esse usuário'" v-if="data.ie_situacao == 'AE'") Solicitação de Exclusão
                        //- Column(headerStyle="width: 5%;" bodyClass="ta-center" field="ie_sexo" header="Sexo")
                        //- Column(headerStyle="width: 10%;" bodyClass="ta-center" field="nr_telefone_f" header="Telefone")
                        Column(headerStyle="width: 8%;" header="Aporte" bodyClass="ta-center" field="nr_valor_apurado_f")
                        Column(headerStyle="width: 8%;" header="Rendimento" bodyClass="ta-center" field="nr_valor_rendido_f")
                        Column(headerStyle="width: 8%;" header="Utilização" bodyClass="ta-center" field="nr_valor_utilizado_f")
                        Column(headerStyle="width: 8%;" header="Saldo" bodyClass="ta-center" field="nr_saldo_med_empresa_f")
                        Column(headerStyle="width: 7%;" header="Tipo" bodyClass="ta-center" field="cd_titular")
                            template(#body="{data}")
                                span {{ data.cd_titular? "Dependente" : "Titular" }}
                        Column(headerStyle="width: 8%;" bodyClass="ta-center" header="Dt. Inclusão" field="dt_inclusao_f")
                        Column(headerStyle='width: 8%; text-align: center')
                            template(#header) Ações
                            template(#body='props')
                                .ta-center
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Editar'"
                                        type="button"
                                        icon="jam jam-write"
                                        @click="$router.push(`/paciente/salvar/${ props.data.id }/`)"
                                    )
                    //- DataTable(:value="listaAssociados")
                    //-     Column(headerStyle='width: 25%;' field='nm_pessoa_fisica' header='Nome')
                    //-     Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_cpf_f' header='CPF')
                    //-     Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='dt_nascimento_f' header='Data de nascimento')
                    //-     Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='ie_sexo_f' header='Sexo')
                    //-     Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='nr_telefone_f' header='Telefone')
                    //-     Column(headerStyle='width: 18%;' header='E-mail')
                    //-         template(#body='props')
                    //-             span(v-tooltip.top="props.data.ds_email" style='cursor: pointer;') {{ props.data.ds_email_f }}
                    Paginator(
                        :rows='paginatorAssociados.per_page'
                        :first='(paginatorAssociados.page - 1) * paginatorAssociados.per_page'
                        :totalRecords='paginatorAssociados.count'
                        @page="onPageAssociados")

</template>

<style lang="scss">
    .empresa-salvar {
        .buscar-paciente{
            // max-width:0%;
            &.naoVer {
                display: none;
        }
        }
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
        .tag-restrito {
            background-color: #e91224;
            color: white;
            padding: 2%;
            border-radius: 5px;
        }        
        .tag-ae {
            background-color: #fffc67;
            color: rgb(78, 78, 78);
            padding: 2%;
            border-radius: 5px;
        }
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
        .dialogFaixa {
            width: 60%;
        }
        .link {
            cursor: pointer
        }
        .waitingEmpresas-wrapper {
            text-align: center;
            padding: 80px 0;
            .p-progress-spinner {
                width: 60px;
                height: auto;
            }
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .waiting-status{
            width: 25px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import PickList from 'primevue/picklist'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Paginator from 'primevue/paginator'
    import Textarea from 'primevue/textarea'
    import Dialog from 'primevue/dialog'
    import moment from 'moment'
    import BuscarPaciente from '../Paciente/BuscarPaciente'
    import InputSwitch from 'primevue/inputswitch';
    import Calendar from 'primevue/calendar'
    import InputNumber from 'primevue/inputnumber'
    import CurrencyInput from "@/components/CustomComponents/CurrencyInput";
    import { Empresa, DominioValor } from './../../middleware'
    import {Utils} from "@/middleware"
    import wsConfigs from './../../middleware/configs'
    import DialogUsuario from "@/components/Empresa/DialogUsuario"
    import Checkbox from 'primevue/checkbox'
    import MultiSelect from 'primevue/multiselect'
    import Endereco from '../PessoaJuridica/Components/Endereco.vue'
    import DadosBancarios from '../PessoaJuridica/Components/DadosBancarios.vue'
    import Impostos from '../PessoaJuridica/Components/Impostos.vue'
    import Contato from '../PessoaJuridica/Components/Contato.vue'

    import { PessoaJuridica, DominioValor as Dominio, PlanoContas } from '../../middleware'
    import { fixTelefoneFormat, pCalendarLocale_ptbr  } from './../../utils'
    import { required, minLength } from 'vuelidate/lib/validators'

    const _ = require('lodash');

    export default {
        components: {
            DialogUsuario,
            ProgressBar, Panel, InputText, Button, Password, ProgressSpinner, InputNumber,
            InputMask, SelectButton, Dropdown, Tooltip, PickList, DataTable, Column,
            Paginator, Dialog, BuscarPaciente, InputSwitch, Checkbox, MultiSelect,
            Endereco, DadosBancarios, Impostos, Contato, Textarea, Calendar, CurrencyInput
        },
        directives: { tooltip: Tooltip },
        watch: {
            tipo_data_inclusao: function (novo, velho) {
            this.filters[velho] = null
            },
            tipo_data_atividade: function (novo, velho) {
                this.filters[velho] = null
            },
            "modelContrato.ie_tipo_valor":function(value){
                if(value){
                    if(value == 'VL'){
                        this.modelContrato.nr_percentual_intermediacao = 0
                    }else this.modelContrato.nr_valor_intermediacao = 0
                }
            },
            "tipo_mensalidade": function(value){
                if(value == 1){
                    this.modelContrato.vl_mensalidade = 0
                }
            }
        },
        created () {
            this.getEstados()
            let id = parseInt(this.$route.params.id)
            if (! isNaN(id)) {
                this.getEmpresa(id)

                if (!id) {
                    this.getTiposEmpresa()
                    this.getPlanoContas()
                    this.getTipos()
                    this.getCnaes()
                    this.getDominios()
                }else{
                    Promise.all([this.getDominios()]).then(() => {
                        this.getContrato()
                    })
                }
            }
        },
        data () {
            return {
                model: {
                    ds_tipo_empresa: null,
                    ds_motivo_suspensao: null,
                    cd_pessoa_juridica: {
                        id: null,
                        nr_cnpj: null,
                        nm_fantasia: null,
                        nr_inscricao_estadual: null,
                        nr_inscricao_municipal: null,
                        ie_tipo_pessoa_juridica: null,
                        ds_observacao: null,
                        dt_atualizado: null,
                        nm_usuario_edi: null,
                        ds_razao_social: null,
                        enderecos: [],
                        contatos: [],
                        bancos: [],
                        tributos: [],
                        planos_contas: [],
                        cnaes: [],
                    },
                    ds_observacao: null,
                    nm_responsavel: null,
                    cd_tipo_empresa: null,
                },
                tipo_data_inclusao: "dt_competencia_inclusao",
                tipo_data_atividade: "dt_competencia",
                options: {
                    tipo_data: [
                        { value_atv: "dt_competencia", value_inc: 'dt_competencia_inclusao', label: "Competência"},
                        { value_atv: "dt_intervalo", value_inc: 'dt_intervalo_inclusao', label: "Intervalo"}
                    ],
                    especialidades: [],
                    procedimentos: [],
                    tiposEmpresa: [],
                    ufs: [],
                    cidades: [],
                    ie_motivo:[],
                    tipo_acao: [],
                    ie_tipo_status: [],
                    ie_situacao: [],
                    ie_status: [
                        { text: 'TODOS', value: null },
                        { text: 'Ativo', value: true },
                        { text: 'Inativo', value: false },
                    ],
                    // tipo_data: [
                    //     { value: "dt_competencia", label: "Competência"},
                    //     { value: "dt_intervalo", label: "Intervalo"}
                    // ],
                    ie_tipo_contrato: [],
                    ie_tipo_valor: [],
                    tipo_mensalidade: [
                        { text: 'Valor Único', value: 0 },
                        { text: 'Por faixa Étaria', value: 1 }
                    ]
                },
                filters: {
                    nm_pessoa_fisica: '',
                    nr_cpf: '',
                    dt_competencia_inclusao: null,
                    dt_intervalo_inclusao: null,
                    dt_competencia: null,
                    dt_intervalo: null,
                    ie_status: 'true',
                    ie_motivo: null
                },
                tipo_data_filter: "dt_competencia",
                waiting: false,
                waitingForm: false,
                waitingAssociados: false,
                usuario: {
                    waiting: false,
                    statusIds: [],
                    dialog: false,
                    model: {}
                },
                ptbr: pCalendarLocale_ptbr,
                waitingTipoEmpresa: false,
                waitingEstado: false,
                waitingCidade: false,
                submitted: false,
                usuarios: [],
                listaAssociados: [],
                paginatorAssociados: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                params: {},
                getAssociadosDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 500, { leading: true }),

                //Variáveis de dados contratuais
                modelContrato: {
                    id: null,
                    ds_contrato:'',
                    ds_observacao:'',
                    dt_vigencia_inicial:null,
                    dt_vigencia_final:null,
                    ie_tipo_contrato:null,
                    nr_vidas_contempladas:null,
                    nr_valor_intermediacao:0,
                    ie_contempla_dependentes:false,
                    ie_tipo_valor:'VL',
                    nr_percentual_intermediacao:0,
                    nr_dia_vencimento_taxa:null,
                    vl_mensalidade:0,
                    nr_dia_vencimento_mensalidade:null,
                    nr_dia_vencimento_resgate:null,
                    nm_responsavel: '',
                    nr_telefone: '',
                    nr_cpf: '',
                },
                modelFaixa: {},
                submittedContrato: false,
                intervalo_vigencia_valido: true,
                arquivoContrato: null,
                waitingContrato: false,
                tipo_mensalidade: 0,
                dialogFaixa: false,
                faixasEtarias: [],
                dominioFaixas: [],
                waitingFaixas: false,
                pt_br: pCalendarLocale_ptbr,
                esseAno: parseInt(moment().format("YYYY")),
                iAdmin: false,
                isAdd: false,
                clienteAlterado: false,
                dialogAnexos: false,
                anexos: [],
                waitingAnexos: false,
                valorAporte: 0,
                arquivoCorrentistas: null,
                waitingAddC: false
            }
        },
        validations () {
            let v = {
                model: {
                    cd_pessoa_juridica: {
                        ds_razao_social: { required, minLength: minLength(2) },
                        nm_fantasia: { required, minLength: minLength(2) },
                        nr_cnpj: { required, minLength: minLength(2) },
                        enderecos: { required },
                        bancos: { required },
                        planos_contas: { required },
                        cnaes: { required },
                    },
                    // nm_responsavel: { required, minLength: minLength(2) },

                },
                modelContrato: {
                    dt_vigencia_inicial:{ required },
                    ie_tipo_contrato:{ required },
                    ie_contempla_dependentes:{ required },
                    ie_tipo_valor:{ required },
                    nr_dia_vencimento_taxa:{ required },
                    nr_dia_vencimento_mensalidade:{ required },
                    nr_dia_vencimento_resgate:{ required },
                }
            }
            return v
        },
        methods: {
            downaloadContrato(){
                window.open(this.modelContrato.aq_anexo, '_blank', 'noreferrer')
            },
            getContrato(){
                Empresa.findAllContrato({cd_empresa: parseInt(this.$route.params.id)}).then(response => {
                    if(([200,201,204]).includes(response.status)){
                        if(response.data.length > 0){
                            this.getFaixas()
                            if(!response.data[0].vl_mensalidade) this.tipo_mensalidade = 1
                            this.modelContrato = {
                                ...response.data[0],
                                // dt_vigencia_inicial: moment(response.data[0].dt_vigencia_inicial, 'YYYY-MM-DD'),
                                vl_mensalidade: +response.data[0]?.vl_mensalidade,
                                dt_vigencia_inicial: moment(response.data[0].dt_vigencia_inicial)._d,
                                dt_vigencia_final: response.data[0].dt_vigencia_final? moment(response.data[0].dt_vigencia_final)._d : null,
                                nr_percentual_intermediacao: +response.data[0].nr_percentual_intermediacao,
                                nr_valor_intermediacao: +response.data[0].nr_valor_intermediacao
                            }
                        }else this.faixasEtarias = this.dominioFaixas
                    }
                })
            },
            setMinDate() {
                this.modelContrato.dt_vigencia_inicial = moment()._d;
            },
            getMinDate() {
                return moment()._d;
            },
            async getDominios(){
                await DominioValor.findAll({ds_mnemonico: ['TIPO_STATUS_HISTORICO', 'TIPO_CONTRATO_EMPRESA', 'TIPO_VALOR_CONTRATO_MED_EMPRES', 'TABELA_FAIXA_ETARIA', 'SITUACAO_HISTORICO_ASSOCIADO', 'MOTIVO_INATIVAR_CORRENTISTA']}).then(response => {
                if (response.status == 200){
                    response.data['TIPO_CONTRATO_EMPRESA'].valores.forEach((un) => {
                            this.options.ie_tipo_contrato.push({ value: un.ie_valor, label: un.ds_valor})
                        });
                    this.options.ie_tipo_valor = response.data['TIPO_VALOR_CONTRATO_MED_EMPRES'].valores.map(item => ({
                        value: item.ie_valor,
                        label: item.ds_valor
                    }))

                    this.dominioFaixas = response.data['TABELA_FAIXA_ETARIA'].valores.map((item) => ({
                        value: item.ie_valor,
                        label: _.last(response.data['TABELA_FAIXA_ETARIA'].valores).id != item.id?
                            `${item.ds_valor.split(',')[0]} a ${item.ds_valor.split(',')[1]} anos de idade`:
                            `A partir de ${item.ds_valor.split(',')[0]} anos`,
                        nr_idade_inicial: +item.ds_valor.split(',')[0],
                        nr_idade_final: +item.ds_valor.split(',')[1],
                        nr_valor: 0,
                    }))
                    response.data['SITUACAO_HISTORICO_ASSOCIADO'].valores.forEach(item => {
                        this.options.ie_situacao.push({value: item.ie_valor, label: item.ds_valor})
                    })
                    this.options.ie_motivo = response.data['MOTIVO_INATIVAR_CORRENTISTA'].valores.map(item => ({
                        value: item.ie_valor,
                        label: item.ds_valor
                    }))
                    // response.data['MOTIVO_INATIVAR_CORRENTISTA'].valores.forEach(item => {
                    //     this.options.ie_motivo.push({value: item.ie_valor, label: item.ds_valor})
                    // })
                    response.data['TIPO_STATUS_HISTORICO'].valores.forEach(item => {
                        this.options.ie_tipo_status.push({value: item.ie_valor, label: item.ds_valor})
                    })
                }
                })
            },
            onpenDialogUsuario(model) {
                this.usuario.model = Object.assign({}, model)
                this.usuario.dialog = true
            },
            _getEndereco() {
                if (this.model?.cd_pessoa_juridica?.enderecos?.length) {
                    return this.model.cd_pessoa_juridica.enderecos[0]
                }
                return {
                    nr_cep: null,
                    nm_logradouro: null,
                    nr_numero: null,
                    ds_complemento: null,
                    nm_bairro: null,
                }
            },
            _getContato() {
                if (this.model?.cd_pessoa_juridica?.contatos?.length) {
                    return this.model.cd_pessoa_juridica.contatos[0]
                }
                return {
                    nr_telefone: null,
                    nm_contato: null,
                    nr_celular: null,
                    nr_whatsapp: null,
                    ds_email: null,
                }
            },
            getEmpresa(id) {
                if (!id) return
                this.waiting = true
                Empresa.find(id).then(response => {
                    if (response.status === 200) {
                        let keys = Object.keys(this.model)
                        keys.forEach(key => {
                            if(response.data[key] != null)
                                this.model[key] = response.data[key]
                            })
                        this.model.id = id
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        if (response.data.dt_atualizado) {
                            this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm')
                        }
                        if (this.model.cd_estado) {
                            this.getCidades()
                            this.model.cd_cidade = response.data.cd_cidade
                        }
                        this.filtrarAssociados()
                        this.getUsuariosEmpresa()
                    }
                    this.getTiposEmpresa()
                    this.getPlanoContas()
                    this.getTipos()
                    this.getCnaes()
                    this.waiting = false
                })
            },
            getAssociados(params = {}){
                params.cd_empresa = this.model.id
                this.waitingAssociados = true
                Empresa.listarAssociados(params).then( response => {
                    if (response.status === 200) {
                        response.data.results.forEach(paciente => {
                            paciente.nm_pessoa_fisica = paciente.cd_titular ? `${paciente.nm_pessoa_fisica} (dependente)` : paciente.nm_pessoa_fisica
                            paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
                            paciente.dt_inclusao_f = moment(paciente.dt_inclusao).format('DD/MM/YYYY')
                            paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
                            if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)
                            if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            paciente.nr_valor_apurado_f = this.$root.formatPrice(paciente.nr_valor_apurado)
                            paciente.nr_valor_rendido_f = this.$root.formatPrice(paciente.nr_valor_rendido)
                            paciente.nr_valor_utilizado_f = this.$root.formatPrice(paciente.nr_valor_utilizado)
                            paciente.nr_saldo_med_empresa_f = this.$root.formatPrice(paciente.nr_saldo_med_empresa)
                        })
                        this.listaAssociados = response.data.results

                        this.totalizadores = [_.omit(response.data, ['results'])]
                        this.paginatorAssociados.count = response.data.count
                    }
                    this.waitingAssociados = false
                })
            },
            getTipos() {
                this.waitingTipo = true
                Dominio.findAll({ ds_mnemonico: 'TIPO_PESSOA_JURIDICA' }).then((response) => {
                    if (response.status === 200) {
                        this.options.tipos = response.data['TIPO_PESSOA_JURIDICA'].valores
                    }
                    this.waitingTipo = false
                })
            },
            getPlanoContas() {
                this.waitingPlanoContas = true
                PlanoContas.findAll().then(response => {
                    if ([200, 201].includes(response.status)) {
                        this.options.contasFinanceiras = response.data
                        let new_plano_contas = []

                        if (this.model.cd_pessoa_juridica.planos_contas) {
                            this.model.cd_pessoa_juridica.planos_contas.forEach(conta => {
                                for (let option of this.options.contasFinanceiras) {
                                    if (conta.cd_plano_contas && conta.cd_plano_contas == option.id) {
                                        new_plano_contas.push(option)
                                        this.model.cd_pessoa_juridica.planos_contas = new_plano_contas
                                        break
                                    }
                                }
                            })
                        }
                    }
                    this.waitingPlanoContas = false
                })
            },
            getCnaes() {
                this.waitingCnae = true
                PessoaJuridica.findAllCnae().then(response => {
                    if ([200, 201].includes(response.status)) {
                        this.options.cnaes = response.data?.map(cnae => {
                            return {
                                ds_descricao: cnae.ds_descricao,
                                cod_cnae: cnae.id
                            }
                        })

                        let new_cnae = []
                        if (this.model.cd_pessoa_juridica.cnaes) {
                            this.model.cd_pessoa_juridica.cnaes.forEach(cnae => {
                                for (let option of this.options.cnaes) {
                                    if (cnae.cod_cnae && cnae.cod_cnae == option.cod_cnae) {
                                        new_cnae.push(option)
                                        this.model.cd_pessoa_juridica.cnaes = new_cnae
                                        break
                                    }
                                }
                            })
                        }
                    }
                    this.waitingCnae = false
                    this.$forceUpdate()
                })
            },
            handleEndereco(endereco) {
                this.model.cd_pessoa_juridica.enderecos = endereco
                this.$forceUpdate()
            },
            handleContatos(contatos) {
                this.model.cd_pessoa_juridica.contatos = contatos
                this.$forceUpdate()
            },
            handleBancos(bancos) {
                this.model.cd_pessoa_juridica.bancos = bancos
                this.$forceUpdate()
            },
            handleTributos(tributos) {
                this.model.cd_pessoa_juridica.tributos = tributos
                this.$forceUpdate()
            },
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.model.$invalid) {
                    return 0
                }
                let dataSend = _.cloneDeep(this.model)
                dataSend.cd_pessoa_juridica.nr_cnpj = dataSend.cd_pessoa_juridica.nr_cnpj.replace(/\D/g, "")
                if (dataSend.cd_pessoa_juridica.planos_contas.length){
                    dataSend.cd_pessoa_juridica.lista_planos_contas = dataSend.cd_pessoa_juridica.planos_contas.map(element => element.id)
                    dataSend.cd_pessoa_juridica.planos_contas = dataSend.cd_pessoa_juridica.planos_contas.map(element => element.id)
                }

                if (dataSend.cd_pessoa_juridica.cnaes.length) {
                    dataSend.cd_pessoa_juridica.lista_cnae = dataSend.cd_pessoa_juridica.cnaes.map(cnae => cnae.cod_cnae)
                }
                if (dataSend.cd_pessoa_juridica.bancos.length) {
                    dataSend.cd_pessoa_juridica.bancos.map(element => {
                        element.cd_banco = element.cd_banco.id
                        if (element.ds_tipo_pix) {
                            element.ds_tipo_pix = element.ds_tipo_pix.value
                        }
                        return element
                    })
                }

                this.waitingForm = true
                Empresa.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'empresas' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else Object.values(response.data).forEach(errorMsg => {
                                if (typeof errorMsg == 'object')
                                    this.$toast.error(errorMsg[0], { duration: 3000 })
                                else this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
            getEstados() {
                this.waitingEstado = true
                Utils.getUFs().then(response => {
                    this.waitingEstado = false
                    this.options.ufs = [{ value: null, text: '- Selecione -', textAlt: '- Selecione -' }]
                    if (response.status === 200) response.data.forEach(uf => {
                        this.options.ufs.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado })
                    })
                })
            },
            getCidades() {
                this.model.cd_cidade = null
                this.options.cidades = []
                if (! this.model.cd_estado) return

                this.waitingCidade = true
                Utils.getMunicipiosEstadoId(this.model.cd_estado).then(response => {
                    this.waitingCidade = false
                    this.options.cidades = [{ value: null, text: '- Selecione -' }]
                    if (response.status === 200) response.data.forEach(cidade => {
                        this.options.cidades.push({ value: cidade.id, text: cidade.nm_cidade })
                    })
                })
            },
            getTiposEmpresa() {
                this.waitingTipoEmpresa = true
                Empresa.getTiposEmpresa().then(response => {
                    this.waitingTipoEmpresa = false
                    if (response.status === 200) {
                        this.options.tiposEmpresa = response.data.map(tipo => {
                            return { value: tipo.id, text: tipo.ds_tipo_empresa }
                        })
                    }
                })
            },
            getUsuariosEmpresa() {
                this.usuario.waiting = true
                Empresa.listRevendedores({ cd_empresa: this.model.id }).then(response => {
                    this.usuario.waiting = false
                    if (response.status === 200) {
                        this.usuarios = response.data.map(usuario => {
                            return {
                                full_name: `${usuario.first_name} ${usuario.last_name}`,
                                ...usuario
                            }
                        })
                    }
                })

            },
            // getDominios() {
            //     DominioValor.findAll({ds_mnemonico: ['SITUACAO_HISTORICO_ASSOCIADO', 'MOTIVO_INATIVAR_CORRENTISTA', 'TIPO_STATUS_HISTORICO']}).then(response => {
            //         if(response.status === 200) {
            //             response.data['SITUACAO_HISTORICO_ASSOCIADO'].valores.forEach(item => {
            //                 this.options.ie_situacao.push({value: item.ie_valor, label: item.ds_valor})
            //             })
            //             this.options.ie_motivo = response.data['MOTIVO_INATIVAR_CORRENTISTA'].valores.map(item => ({
            //                 value: item.ie_valor,
            //                 label: item.ds_valor
            //             }))
            //             // response.data['MOTIVO_INATIVAR_CORRENTISTA'].valores.forEach(item => {
            //             //     this.options.ie_motivo.push({value: item.ie_valor, label: item.ds_valor})
            //             // })
            //             response.data['TIPO_STATUS_HISTORICO'].valores.forEach(item => {
            //                 this.options.ie_tipo_status.push({value: item.ie_valor, label: item.ds_valor})
            //             })
            //         }
            //     })
            // },

            alterarStatusUsuario(usuario) {
                const dataSend = { id: usuario.id, is_active: !usuario.is_active }
                this.usuario.statusIds.push(usuario.id)
                Empresa.saveUsuario(dataSend).then(response => {
                    this.usuario.statusIds = this.usuario.statusIds.filter(x => x !== usuario.id)
                    if (response.status === 200) {
                        usuario.is_active = !usuario.is_active
                    }
                })
            },
            filtrarAssociados(page, force=false) {
                if (!this.model.id) return;

                this.paginatorAssociados.page = page || 1;
                let params = {
                    cd_empresa: this.model.id,
                    paginacao: true,
                    page: this.paginatorAssociados.page,
                    per_page: this.paginatorAssociados.per_page,
                    ie_associado_med_empresa: true
                };
                Object.keys(this.filters).forEach(key => {
                    if (this.filters[key]) {
                        if (key === 'nr_cpf')
                            params[key] = this.filters[key].replace(/(\.|-|_)/g, '');
                        else if (key.startsWith('dt_')) {
                            var aux = _.isArray(this.filters[key])
                                        ? this.filters[key][1]
                                            ? this.filters[key]
                                            : [this.filters[key][0]]
                                        : [this.filters[key]]
                            const tipo = 'tipo_data_' + key.includes('intervalo') ? 'intervalo' : 'atividade'

                        params[key] = _.map(aux,
                                d => moment(d).format(
                                    this[tipo] == 'dt_competencia'
                                        ? 'YYYY-MM'
                                        : 'YYYY-MM-DD'))
                        } else
                            params[key] = this.filters[key];
                    }
                });
                // Fake loading quando os filtros forem os mesmos
                if (!force && _.isEqual(this.params, params)) {
                    if (!this.waitingAssociados) {
                        this.waitingAssociados = true;
                        setTimeout(() => this.waitingAssociados = false, 300);
                    }
                } else {
                    this.params = Object.assign({}, params);
                    this.getAssociados(params);
                }
            },
            onPageAssociados(ev) {
                this.filtrarAssociados(ev.page + 1)
            },
            anexarContrato(){
                const files = this.$refs.arquivoContrato.files
                const comp = files.length && files[0];
                if (!comp) return;
                this.arquivoContrato = files
            },
            submitContrato(){
                const files = this.$refs.arquivoContrato.files
                if(this.tipo_mensalidade == 1 && !this.faixasEtarias.every(item => item.nr_valor > 0)){
                    this.$toast.error("Os valores por faixa etária precisam ser maiores que '0.00'")
                    return
                }
                if (!(files.length && files[0]) && !this.modelContrato.aq_anexo) {
                    this.$toast.error("Adicione o contrato")
                    return
                }
                this.submittedContrato = true
                this.$v.$touch()
                if (this.$v.modelContrato.$invalid) return 0
                let contrato = Object.assign({}, this.modelContrato)
                const metodo = this.modelContrato.id? 'patch' : 'post'
                let responsavel = {}
                if(contrato.nm_responsavel) responsavel.nm_responsavel = contrato.nm_responsavel
                if(contrato.nr_telefone) responsavel.nr_telefone = contrato.nr_telefone.replace('(',''). replace(')', '')
                if(contrato.nr_cpf) responsavel.nr_cpf = contrato.nr_cpf.replace(/\D/g, '')
                contrato = {
                    ...contrato,
                    cd_empresa: parseInt(this.$route.params.id),
                    responsavel: Object.keys(responsavel).length == 0? null : responsavel,
                    aq_anexo: files || this.modelContrato.aq_anexo,
                    vl_mensalidade: this.tipo_mensalidade == 0 ? contrato.vl_mensalidade : null,
                    // nr_cpf: this.modelContrato.nr_cpf.replace(/\D/g, ''),
                    // nr_telefone: this.modelContrato.nr_telefone.replace('(',''). replace(')', ''),
                    dt_vigencia_final: contrato.dt_vigencia_final? moment(contrato.dt_vigencia_final).format('YYYY-MM-DD') : null,
                    dt_vigencia_inicial: moment(contrato.dt_vigencia_inicial).format('YYYY-MM-DD')
                }
                console.log(contrato)
                this.waitingContrato = true
                Empresa.saveContrato(contrato, metodo).then(response => {
                    this.waitingContrato = false
                    if(([200,201,204]).includes(response.status)){
                        if(this.tipo_mensalidade == 1){
                            let dataSendFaixas ={
                                cd_empresa_contrato: this.modelContrato.id || response.data.id,
                                ie_atualizacao_contrato: this.modelContrato.id? true : false,
                                faixas: this.faixasEtarias.map(item => ({
                                    ie_tipo: "A",
                                    nr_idade_inicial: item.nr_idade_inicial,
                                    nr_idade_final: item.nr_idade_final,
                                    nr_valor: item.nr_valor
                                }))
                            }
                            Empresa.addFaixas(dataSendFaixas).then(responseContrato => {
                                if(([200,201,204]).includes(responseContrato.status)){
                                    this.$toast.success("Faixas adicionadas com sucesso")
                                }
                            })
                        }
                        this.$toast.success('Contrato inserido com sucesso')
                        return
                    }
                    Object.keys(response.data).forEach(key => {
                        this.$toast.error(`${response.data[key]}`)
                    })
                })
            },

            getFaixas(){
                Empresa.getFaixas({ cd_empresa: parseInt(this.$route.params.id), ie_vigente: true }).then(response => {
                    if(([200,201,204]).includes(response.status)){
                        if(this.dominioFaixas.length == response.data.length){
                            this.faixasEtarias = response.data.map(item => ({
                                value: item.id,
                                label: _.last(response.data).id != item.id?
                                    `${item.nr_idade_inicial} a ${item.nr_idade_final} anos de idade`:
                                    `A partir de ${item.nr_idade_inicial} anos`,
                                nr_idade_inicial: item.nr_idade_inicial,
                                nr_idade_final: item.nr_idade_final,
                                nr_valor: item.nr_valor,
                            }))
                        }
                        else this.faixasEtarias = this.dominioFaixas
                        // console.log("aqui")
                        // console.log("AQUIIII",response.data)
                        // this.faixasEtarias = response?.data?.id ? response.data : this.dominioFaixas
                    }
                })

            },
            addCorrentista(){
                let dataSend = {
                    cd_empresa: this.model.id,
                    cd_pessoa_fisica: this.$refs.buscarPaciente2.model.id,
                    nr_valor_aporte: this.valorAporte,
                    file_termo_adesao: this.arquivoContrato[0]
                }
                this.waitingAddC = true;
                Empresa.adicionarCorrentistasArquivo(dataSend).then(response => {
                    this.waitingAddC = false;
                    if(([200,201,204]).includes(response.status)){
                        // this.listaAssociados.push({
                        //     id: this.$refs.buscarPaciente2.model.id,
                        //     nm_pessoa_fisica: this.$refs.buscarPaciente2.model.nm_pessoa_fisica,
                        //     nr_cpf_f: this.$refs.buscarPaciente2.model.nr_cpf_f,
                        //     dt_nascimento_f: this.$refs.buscarPaciente2.model.dt_nascimento_f,
                        //     ie_sexo_f: this.$refs.buscarPaciente2.model.ie_sexo_f,
                        //     cd_pai: this.$refs.buscarPaciente2.model.cd_pai,
                        //     cd_titular: this.$refs.buscarPaciente2.model.cd_pai,
                        //     nr_saldo_med_empresa: 0,
                        //     nr_saldo_med_empresa_f: this.formatPrice(0),
                        //     ds_email: this.$refs.buscarPaciente2.model.ds_email,
                        //     nr_telefone: this.$refs.buscarPaciente2.model.nr_telefone,
                        //     ie_status: true
                        // })
                        let params = {
                                cd_empresa: this.model.id,
                                paginacao: true,
                                page: this.paginator.page,
                                per_page: this.paginator.per_page,
                                ie_associado_med_empresa: true
                            };
                        this.getAssociados(params)
                        this.isAdd = false;
                        this.isAdd = false;
                        this.clienteAlterado = false;
                    }
                })
            },
            anexarCorrentistas(){
                const files = this.$refs.arquivoCorrentistas.files
                const comp = files.length && files[0];
                if (!comp) return;
                let dataSend = {
                    cd_empresa: this.model.id,
                    file: files
                }
                this.waitingAssociados = true;
                Empresa.adicionarCorrentistasArquivo(dataSend).then(response => {
                    this.waitingAssociados = false;
                    if(([200,201,204]).includes(response.status)){
                        if(response.data.erros.nr_planilha > 0){
                            this.dialogErros = true
                            this.erros.erros_planilha = Object.keys(response.data.erros.erros_planilha).map(key => ({
                            text: `${key} - ${response.data.erros.erros_planilha[key]}`
                            }))
                        }
                        if(response.data.erros.nr_cadastro > 0){
                            this.dialogErros = true
                            this.erros.erros_correntista = Object.keys(response.data.erros.erros_correntista).map(key => ({
                            text: `CPF: ${key} - ${response.data.erros.erros_correntista[key]}`
                            }))
                        }
                        this.totalAcertos = response.data.cadastros.nr_total
                        this.totalErros = response.data.erros.nr_total

                        if(!this.dialogErros)
                            this.$toast.success("Correntistas adicionados com sucesso")
                            let params = {
                                cd_empresa: this.model.id,
                                paginacao: true,
                                page: this.paginator.page,
                                per_page: this.paginator.per_page,
                                ie_associado_med_empresa: true
                            };
                        this.getAssociados(params)
                        return
                    }
                    this.$toast.error(response?.data?.erros?.erros_planilha?.critical || "Planilha inconsistente")
                })
            },

        }
    }
</script>
