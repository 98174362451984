<template lang="pug">
ProgressBar(v-if="waiting" mode="indeterminate")
div(v-else)
    .p-grid.p-fluid.p-align.end
        .p-col-12.p-md-2(v-for="(item, index) in tributos" style="height: 120px")
            label.form-label {{ item.ie_tipo_tributo }}
            InputSwitch(v-model="item.ie_marcado" @click="toggleChange(item)")
            .div(v-if="item.ie_marcado")
                label.form-label Alq:
                InputNumber(
                    suffix=" %"
                    :value="item.vl_aliquota"
                    @input="setNovoValor(item, $event)"
                    :minFractionDigits="2"
                    :disabled="item.ie_permite_alterar.toUpperCase() == 'S' ? false : true"
                )


</template>

<style></style>

<script>
    import InputSwitch from 'primevue/inputswitch';
    import InputNumber from 'primevue/inputnumber';
    import ProgressBar from 'primevue/progressbar';
import { Tributos } from '../../../middleware';

    export default {
        components: {
            InputSwitch, InputNumber, ProgressBar
        },
        created () {
            this.getTributos()
        },
        props: ['parentData', 'pessoaJuridicaId'],
        data() {
            return {
                tributos: [],
            }
        },
        methods: {
            getTributos() {
                this.waiting = true
                Tributos.findAll().then(response => {
                    if (response.status == '200') {
                        this.tributos = response.data
                        this.tributos.map(tributo => {
                            tributo.novo_valor = 0
                            tributo.ie_marcado = false
                            return tributo
                        })

                        if (this.parentData) {
                        this.tributos.forEach(tributo => {
                            if (this.parentData.some(pTributo => pTributo.cd_tributo === tributo.id)) {
                                tributo.vl_aliquota = this.parentData.find(pTributo => pTributo.cd_tributo === tributo.id).aliquota
                                tributo.ie_marcado = true
                            }
                        })
                        this.$forceUpdate()
            }
                    }
                    this.waiting = false
                    this.$forceUpdate()
                })
            },
            setNovoValor(tributo, valor) {
                if (tributo.vl_aliquota != valor) {
                    tributo.novo_valor = valor
                } else {
                    tributo.novo_valor = valor
                }
                this.emit()
            },
            toggleChange(tributo) {
                tributo.ie_marcado = !tributo.ie_marcado
                this.emit()
                this.$forceUpdate()
            },
            emit() {
                let emitData = []
                this.tributos.forEach(tributo => {
                    if (tributo.ie_marcado) {
                        emitData.push({
                            id: tributo.id,
                            vl_aliquota: tributo?.novo_valor
                        })
                    }
                })
                this.$emit('tributosEmitidos', emitData)
            }
        }
    }
</script>
